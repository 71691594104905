<template>
    <CommonTemplate
        class="final-page"
    >
        <h1 class="common-t__title">Мы подобрали Вам займы.</h1>
        <div class="final-page__desc">
            <span>Для гарантированного получения займа подайте заявки как минимум в 3 компании.</span>
        </div>
        <List/>
        <base-button-up></base-button-up>
    </CommonTemplate>
</template>

<script>
import './final-page.scss';

import List from '@/applications/final/components/list/List';


export default {
    name: 'Final',
    created() {
        const t = document.createElement('script');
        t.type = 'text/javascript';
        t.async = true;
        t.src = 'https://vk.com/js/api/openapi.js?169';
        t.onload = function() {
            // eslint-disable-next-line no-undef
            VK.Retargeting.Init('VK-RTRG-1395828-akyEf');
            // eslint-disable-next-line no-undef
            VK.Retargeting.Hit()
        };
        document.head.appendChild(t)

        if (document.querySelector('script[src="//web.webpushs.com/js/push/903fc2a3e226557be831a4bf46522d3b_1.js"]'))
            return

        const script = document.createElement('script')
        script.src = '//web.webpushs.com/js/push/903fc2a3e226557be831a4bf46522d3b_1.js'
        script.async = true
        document.head.appendChild(script)
    },
    components: {
        List
    },
    // beforeRouteEnter(to, from, next) {
    //     if (Cookies.get('sbg-cpa')) {
    //         next()
    //     } else {
    //         Store.commit('application/load', false)
    //         next({ name: 'PersonalAreaProfile' })
    //     }
    // }
}
</script>